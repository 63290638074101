package se.fluen.web.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.vw
import se.fluen.styles.ButtonLinkVariant
import se.fluen.styles.OutlinedButtonLinkVariant
import se.fluen.web.components.widgets.Anchor
import se.fluen.web.components.widgets.Feature
import se.fluen.web.values.Res

@Composable
fun Features() {
    Anchor("features")
    Column(Modifier.gap(clamp(7.cssRem, 20.vw, 15.cssRem)).margin(topBottom = 10.vw)) {
        Feature(
            Res.string.feature_study_modes_headline,
            Res.string.feature_study_modes_subheadline,
            Res.string.feature_study_modes_description,
            Res.image.feature_study_modes,
            imageFirst = true
        ) {
            Link(Res.link.features, Res.string.learn_more, variant = ButtonLinkVariant)
        }
        Feature(
            Res.string.feature_smart_headline,
            Res.string.feature_smart_subheadline,
            Res.string.feature_smart_description,
            Res.image.feature_smart
        ) {
            Link(Res.link.features, Res.string.try_now, variant = OutlinedButtonLinkVariant)
        }
        Feature(
            Res.string.feature_cloud_headline,
            Res.string.feature_cloud_subheadline,
            Res.string.feature_cloud_description,
            Res.image.feature_cloud,
            true
        ) {
            Link(Res.link.features, Res.string.learn_more, variant = ButtonLinkVariant)
        }
    }
}