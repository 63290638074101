package se.fluen.web.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.graphics.ImageStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import gg.bluepeak.kobwebsdk.theme.toSitePalette
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.H1
import se.fluen.web.values.Res

val ShowcaseImageStyle = ImageStyle.addVariantBase {
    Modifier.height(clamp(34.cssRem, 30.vw, 30.vw)).borderRadius(12.px)
}

@Composable
fun Showcase() {
    val colorMode by ColorMode.currentState
    Column(
        Modifier.width(99.vw).backgroundImage(linearGradient(LinearGradient.Direction.ToTop) {
            add(Colors.Transparent, 30.percent)
            add(colorMode.toSitePalette().primary, 30.percent)
            add(colorMode.toSitePalette().primary, 100.percent)
        }),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        H1(Modifier.textAlign(TextAlign.Center).toAttrs()) {
            SpanText(Res.string.showcase_headline)
        }
        Row(Modifier.gap(2.cssRem).margin(topBottom = 3.cssRem)) {
            Image(
                if (colorMode.isLight) Res.image.showcase_card_light else Res.image.showcase_card_dark,
                Modifier.displayIfAtLeast(Breakpoint.MD).margin(top = 35.px),
                variant = ShowcaseImageStyle
            )
            Image(
                if (colorMode.isLight) Res.image.showcase_cardlist_light else Res.image.showcase_cardlist_dark,
                variant = ShowcaseImageStyle
            )
            Image(
                if (colorMode.isLight) Res.image.showcase_deck_light else Res.image.showcase_deck_dark,
                Modifier.displayIfAtLeast(Breakpoint.MD).margin(top = 35.px),
                variant = ShowcaseImageStyle
            )
        }
    }
}