package se.fluen.web.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.window
import se.fluen.web.values.Res

@Composable
fun LanguageSelector() {
    se.fluen.components.widgets.LanguageSelector(
        Res.string.language_selector_headline,
        Res.string.locale,
        Res.locales
    ) {
        window.location.replace(window.location.href.replace("/${Res.string.locale}/", "/${it.iso}/"))
    }
}