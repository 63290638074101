package se.fluen.web.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import kotlinx.browser.document
import kotlinx.browser.window
import se.fluen.web.components.sections.Footer
import se.fluen.web.components.sections.NavHeader
import se.fluen.web.values.Res

@Composable
fun PageLayout(title: String, content: @Composable () -> Unit) {
    LaunchedEffect(title) {
        document.documentElement?.setAttribute("lang", Res.string.locale)
        Res.locales.forEach {
            document.head?.append(document.createElement("link").apply {
                setAttribute("rel", "alternate")
                setAttribute("hreflang", it.iso)
                setAttribute(
                    "href",
                    "https://fluen.se" + window.location.pathname.replace("/${Res.string.locale}/", "/${it.iso}/")
                        .substringBefore("?")
                )
            })
        }
    }
    se.fluen.components.layouts.PageLayout(
        title,
        @Composable { NavHeader() },
        @Composable { Footer() },
        content = @Composable { content() })
}
